import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { PartyUser } from '../../types/PartyUser.model';
import { db } from './firestore';
import { firestoreConverter } from './firestoreConverter';
import { useAuth } from './useAuth';

const NO_USER = 'NO_USER';

export const usePartyUser = () => {
  const auth = useAuth();
  const ref = doc(db, 'users', auth.userId).withConverter(firestoreConverter<PartyUser>());
  const [partyUser, setPartyUser] = React.useState<PartyUser>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (auth.userId === NO_USER) {
      return;
    }

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      if (snapshot.exists()) {
        setPartyUser(snapshot.data());
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [auth.userId]);


  return { data: partyUser, isLoading };
};

export const usePartyUserMutation = () => {
  const auth = useAuth();
  const ref = doc(db, 'users', auth.userId).withConverter(firestoreConverter<PartyUser>());
  return useMutation({
    mutationFn: (opt: PartyUser) => {
      return setDoc(ref, opt);
    },
  });
};
