import React from 'react';
import { neonifyHr } from '../../utils/neon-utils';
import { PartyUserState } from '../../types/PartyUserState.enum';
import { usePartyUser } from '../../services/firebase/usePartyUser';
import { usePartyList } from '../../services/firebase/useParty';
import { createUseStyles } from 'react-jss';

export interface UsersProps {}

const useStyles = createUseStyles({
  userContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ul: {
    padding: '16px 0',
    margin: '0',
    marginTop: '16px',
  },
  li: {
    listStyle: 'none',
    borderRadius: '3px',
    padding: '8px',
    fontSize: '20px',
    color: 'white',
    marginBottom: 16,
    whiteSpace: 'nowrap',
  },
  hr: {
    height: 3,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 1.5,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  asleep: {
    boxShadow: neonifyHr('#2de2e6'),
  },
  awake: {
    boxShadow: neonifyHr('#f9c80e'),
  },
});

const Users: React.FunctionComponent<UsersProps> = () => {
  const classes = useStyles();
  const partyUser = usePartyUser();
  const partyList = usePartyList();

  return (<>
    <ul className={classes.ul}>
      {partyList.data.map(user => (
        <li className={classes.li} key={user.id}>
          <div className={classes.userContainer}>
            <span className={classes.name}>
              {`${user.name} ${user.id === partyUser.data?.id ? '(me)' : ''}`}
            </span>
            <span>
              {user.state === PartyUserState.AWAKE ? '🔆 Awake' : '🌙 Asleep'}
            </span>
          </div>
          <hr className={`${classes.hr} ${user.state === PartyUserState.AWAKE ? classes.awake : classes.asleep}`} />
        </li>
      ))}
    </ul>
  </>);
};

export default Users;
