import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { neonifyText } from '../../utils/neon-utils';

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}
const useStyles = createUseStyles({
  overlay: {
    zIndex: 1000,
    backgroundColor: '#261447',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  root: (color: string) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#261447',
    paddingTop: 40,
  }),
  close: {
    textShadow: neonifyText('#FF3232'),
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'white',
    fontSize: 48,
    height: 48,
    width: 48,
    cursor: 'pointer',
  },
});
 
const Modal: React.FunctionComponent<ModalProps> = ({ isOpen, children, onClose = () => {} }) => {
  const classes = useStyles();

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ( <>
    {isOpen && <>
      <div className={classes.overlay} onClick={handleOverlayClick}>
        <div className={classes.root}>
          <div className={classes.close} onClick={onClose}>✕</div>
          { children }
        </div>
      </div>
    </>}
  </> );
};
 
export default Modal;