import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { usePartyUser } from '../../services/firebase/usePartyUser';
import Qr from './Qr';
import Share from './Share';

interface InfoProps {}

const useStyles = createUseStyles({
  root: {
    height: 64,
    color: 'white',
    fontSize: 40,
    fontFamily: 'monospace',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    marginTop: 24,
    marginBottom: 56,
  },
  qr: {
    height: 40,
    userSelect: 'none',
    cursor: 'pointer',
  },
  partyId: {
    padding: '0 32px',
  },
  share: {
    height: 40,
    userSelect: 'none',
    cursor: 'pointer',
  },
});

const Info: React.FunctionComponent<InfoProps> = () => {
  const classes = useStyles();
  const party = usePartyUser();
  const url = `${window.location.protocol}//${window.location.host}?joinCode=${party.data?.party}`;

  return (
    <>
      <div className={classes.root}>
        <Qr url={url} />
        <span className={classes.partyId}>{party.data?.party || '----'}</span>
        <Share url={url} />
      </div>
    </>
  );
};

export default Info;
