import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button/Button';
import { usePartyUser } from '../../services/firebase/usePartyUser';
import { neonifyHr } from '../../utils/neon-utils';

interface GoToProps {
  disabled: boolean;
}

const useStyles = createUseStyles({
  partyButton: {
    width: '100%',
    height: 64,
    marginTop: 32,
  },
  partyCodeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 24,
  },
  partyCode: {
    color: 'white',
    fontFamily: 'monospace',
    fontSize: 40,
  },
  partyCodeLabel: {
    color: 'white',
    fontSize: 32,
  },
  hr: {
    height: 3,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 1.5,
    boxShadow: neonifyHr('#E62D8A'),
    width: '100%',
  },
});
 
const GoTo: React.FunctionComponent<GoToProps> = ({ disabled }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const partyUser = usePartyUser();

  return ( <>
    <div className={classes.partyCodeContainer}>
      <span className={classes.partyCodeLabel}>
        Current Party
        <hr className={classes.hr} />
      </span>
      <span className={classes.partyCode}>{partyUser.data?.party || '----'}</span>
    </div>
    <Button disabled={disabled} className={classes.partyButton} color="#2DE6E6" onClick={() => navigate('/party')}>Current Party</Button>
  </> );
};
 
export default GoTo;
