import { QRCodeSVG } from 'qrcode.react';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import qrIcon from '../../assets/qr.svg';
import Modal from '../../common/Modal/Modal';
import { neonifyContainer } from '../../utils/neon-utils';

interface QrProps {
  url: string;
}

const useStyles = createUseStyles({
  iconContainer: {
    padding: 16,
    boxShadow: neonifyContainer('#E62D8A'),
    border: '2px solid white',
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    height: 30,
    userSelect: 'none',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  helperText: {
    marginTop: 24,
    fontSize: 20,
    textAlign: 'center',
  },
});
 
const Qr: React.FunctionComponent<QrProps> = ({ url }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  return ( <>
    <div className={classes.iconContainer} onClick={() => setIsOpen(true)}>
      <img className={classes.icon} src={qrIcon} alt="" />
    </div>
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className={classes.body}>
        <QRCodeSVG bgColor="#261447" fgColor="white" value={url} />
        <span className={classes.helperText}>Let your friends scan this code to easily join this party!</span>
      </div>
    </Modal>
  </> );
};
 
export default Qr;
