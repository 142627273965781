import React from 'react';
import { usePartyUser } from '../../services/firebase/usePartyUser';
import Join from './Join';
import Create from './Create';
import Name from './Name';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParty } from '../../services/firebase/useParty';
import GoTo from './GoTo';
import { toast } from 'react-hot-toast';

export interface HomePageProps {

}

const HomePage: React.FunctionComponent<HomePageProps> = () => {
  const { data: partyUser } = usePartyUser();
  const navigate = useNavigate();
  const [ params, setParams ] = useSearchParams();
  const { join } = useParty();

  const joinCode = params.get('joinCode');
  React.useEffect(() => {
    if (partyUser && joinCode) {
      join.mutate(joinCode, {
        onSuccess: () => {
          navigate('/party');
        },
        onError: (err) => {
          toast.error('Unable to join that party.');
          params.delete('joinCode');
          setParams(params);
        },
      });
    }
  }, [joinCode, partyUser]);
  
  return ( <>
    <Name />
    <GoTo disabled={!partyUser || !partyUser.party} />
    <Create disabled={!partyUser || !!partyUser.party} />
    <Join disabled={!partyUser || !!partyUser.party} />
  </> );
};

export default HomePage;