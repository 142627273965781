import * as React from 'react';
import { toast } from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button/Button';
import { useParty } from '../../services/firebase/useParty';

interface CreateProps {
  disabled: boolean;
}

const useStyles = createUseStyles({
  partyButton: {
    width: '100%',
    height: 64,
    marginTop: 32,
  },
});
 
const Create: React.FunctionComponent<CreateProps> = ({ disabled }) => {
  const classes = useStyles();
  const { create } = useParty();
  const navigate = useNavigate();

  const createParty = () => {
    toast.promise(create.mutateAsync(null, {
      onSuccess: () => {
        navigate('/party');
      },
    }), {
      loading: 'Creating party',
      success: 'Created party',
      error: 'Failed to create party',
    });
  };

  return ( <>
    <Button disabled={disabled} className={classes.partyButton} color="#4275EA" onClick={createParty}>Create Party</Button>
  </> );
};
 
export default Create;