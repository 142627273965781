import * as React from 'react';
import { createUseStyles } from 'react-jss';
import shareIcon from '../../assets/share.svg';
import { neonifyContainer } from '../../utils/neon-utils';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';

interface ShareProps {
  url: string;
}

const useStyles = createUseStyles({
  iconContainer: {
    padding: 16,
    boxShadow: neonifyContainer('#2DE6E6'),
    border: '2px solid white',
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    height: 30,
    userSelect: 'none',
  },
});
const Share: React.FunctionComponent<ShareProps> = ({ url }) => {
  const classes = useStyles();

  const saveToClipboard = () => {
    const success = copy(url);
    if (success) {
      toast.success('Copied to clipboard.', { id: 'copySuccess' });
    } else {
      toast.error('Unable to copy to clipboard.', { id: 'copyError' });
    }
  };

  return (
    <>
      <div className={classes.iconContainer} onClick={saveToClipboard}>
        <img className={classes.icon} src={shareIcon} alt="" />
      </div>
    </>
  );
};

export default Share;
