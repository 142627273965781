// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCa8aiPiN5savv_amFASzRvFrJyLbhNT0c',
  authDomain: 'slumber-7f566.firebaseapp.com',
  projectId: 'slumber-7f566',
  storageBucket: 'slumber-7f566.appspot.com',
  messagingSenderId: '611337627611',
  appId: '1:611337627611:web:4c9202d8e529b6cc44a9fe',
  measurementId: 'G-D5GS166ZED',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize AppCheck
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcOJTUmAAAAAMIgdddJ74dzSUIZmgjVgw1biHnJ'),
  isTokenAutoRefreshEnabled: true,
});
