import * as React from 'react';
import { toast } from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import Modal from '../../common/Modal/Modal';
import { useParty } from '../../services/firebase/useParty';

interface JoinProps {
  disabled: boolean;
}

const useStyles = createUseStyles({
  partyButton: {
    width: '100%',
    height: 64,
    marginTop: 32,
  },
  joinCodeInput: {
    marginBottom: 32,
  },
  joinBody: {
    padding: 24,
  },
  joinButton: {
    width: '100%',
    height: 64,
    marginTop: 16,
    marginBottom: 32,
  },
});

const Join: React.FunctionComponent<JoinProps> = ({ disabled }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const { join } = useParty();

  const navigate = useNavigate();

  const [joinCode, setJoinCode] = React.useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    if (rawValue.length > 4) {
      return;
    }
    
    if (rawValue.length > 0 && !/[a-zA-Z]/i.test(rawValue.slice(-1))) {
      return;
    }

    const sanitizedValue = `${rawValue}`.toUpperCase();
    setJoinCode(sanitizedValue);
  };

  const joinParty = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toast.promise(join.mutateAsync(joinCode, {
      onSuccess: () => {
        setIsOpen(false);
        navigate('/party');
      },
    }), {
      loading: 'Joining party',
      success: 'Joined party',
      error: 'Failed to join party',
    });
  };

  return (
    <>
      <Button disabled={disabled} className={classes.partyButton} color="#A545E6" onClick={() => setIsOpen(true)}>
        Join Party
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <form className={classes.joinBody} onSubmit={e => e.preventDefault()}>
          <Input color="#D3D3D3" className={classes.joinCodeInput} placeholder="Join Code" value={joinCode} onChange={onChange}></Input>
          <Button className={classes.joinButton} color="#2FF22F" onClick={joinParty}>Join</Button>
        </form>
      </Modal>
    </>
  );
};

export default Join;
