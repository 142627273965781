import { signInAnonymously } from 'firebase/auth';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import HomePage from './components/HomePage/HomePage';
import PartyPage from './components/PartyPage/PartyPage';
import { auth } from './services/firebase/auth';
import { useAuth } from './services/firebase/useAuth';
import { neonifyContainer } from './utils/neon-utils';
export interface AppProps {

}

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/party',
    element: <PartyPage />,
  },
]);

const App: React.FunctionComponent<AppProps> = () => {
  const signInInit = React.useRef(false);
  React.useEffect(() => {
    if (!signInInit.current) {
      signInInit.current = true;
      signInAnonymously(auth).then((v) => {
        console.log('Signed in anon', v.user.uid);
      });
    }
  }, []);

  const { isLoading } = useAuth();

  if (isLoading) {
    return <div />;
  }

  return (<>
      <div style={{
        padding: 24,
        minHeight: 'calc(100vh - 88px)',
        maxWidth: 480,
        margin: 'auto',
      }}>
        <Header />
        <RouterProvider router={router} />
        <Footer />
      </div>
      <Toaster gutter={32} toastOptions={{
        style: {
          backgroundColor: '#261447',
          color: 'white',
          border: '2px solid white',
          borderRadius: 3,
          zIndex: 10000,
        },
        success: {
          style: {
            boxShadow: neonifyContainer('#2FF22F'),
          },
        },
        error: {
          style: {
            boxShadow: neonifyContainer('#FF3232'),
          },
        },
        loading: {
          style: {
            boxShadow: neonifyContainer('#2DE6E6'),
          },
        },
      }}/>
  </>);
};

export default App;