import { User } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { auth } from './auth';

type AuthState = {
  isSignedIn: boolean;
  isLoading: boolean;
  user: User | null;
  userId: string,
};

const NO_USER = 'NO_USER';

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    isSignedIn: false,
    isLoading: true,
    user: null,
    userId: NO_USER,
  });

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
      const userId = user?.uid || NO_USER;
      return setAuthState({ user, isLoading: false, isSignedIn: !!user, userId });
    });
    return () => unregisterAuthObserver();
  }, []);

  return { auth, ...authState };
};
