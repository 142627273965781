import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button/Button';
import { useParty } from '../../services/firebase/useParty';

interface LeaveProps {}

const useStyles = createUseStyles({
  button: {
    alignSelf: 'flex-end',
    padding: '8px',
    width: '100%',
    marginTop: 32,
    height: 64,
  },
});

const Leave: React.FunctionComponent<LeaveProps> = () => {
  const classes = useStyles();
  const { leave } = useParty();
  const navigate = useNavigate();

  const leaveParty = () => {
    leave.mutate(null, {
      onSuccess: () => {
        navigate('/');
      },
    });
  };

  return <>
    <Button className={classes.button} color="#FF3232" onClick={leaveParty}>Leave Party</Button>
    {/* TODO: Add confirmation modal */}
  </>;
};

export default Leave;
