import * as React from 'react';

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <>
      {/* filler */}
      <div style={{ height: 48 }}></div>
    </>
  );
};

export default Footer;
