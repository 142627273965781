import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { neonifyContainer } from '../../utils/neon-utils';

interface ButtonProps {
  children: React.ReactNode;
  color?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  button: (color: string) => ({
    borderRadius: '3px',
    width: '120px',
    alignSelf: 'flex-end',
    backgroundColor: '#ffffff2e',
    color: 'white',
    fontSize: '20px',
    padding: '8px',
    border: '2px solid white',
    boxShadow: neonifyContainer(color),
    cursor: 'pointer',
    userSelect: 'none',
    '&:active': {
      backgroundColor: 'white',
      color: color,
    },
    '&.disabled': {
      boxShadow: 'none',
      border: '2px solid #505050',
      color: '#ACACAC',
      backgroundColor: '#0000002e',
      cursor: 'not-allowed',
    },
  }),
});

const Button: React.FunctionComponent<ButtonProps> = ({ children, color = 'blue', className, onClick, disabled = false }) => {
  const classes = useStyles(color);
  return (
    <>
      <button 
        className={`${classes.button} ${disabled ? 'disabled' : ''} ${className}`}
        onClick={onClick}
        disabled={disabled}
      >{children}</button>
    </>
  );
};

export default Button;
