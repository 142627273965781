import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../common/Button/Button';
import { usePartyUser, usePartyUserMutation } from '../../services/firebase/usePartyUser';
import { PartyUserState } from '../../types/PartyUserState.enum';

export interface SleepToggleProps {
}

const useStyles = createUseStyles({
  button: {
    alignSelf: 'flex-end',
    padding: '8px',
    width: '100%',
    height: 64,
  },
});

const SleepToggle: React.FunctionComponent<SleepToggleProps> = () => {
  const classes = useStyles();
  const partyUser = usePartyUser();
  const mutation = usePartyUserMutation();

  const toggleSleep = async () => {
    if (partyUser.data) {
      mutation.mutate({ 
        ...partyUser.data, 
        state: partyUser.data.state === PartyUserState.AWAKE ? PartyUserState.ASLEEP : PartyUserState.AWAKE,
      });
    }
  };

  const awakeText = 'I\'m going to sleep.';
  const asleepText = 'I\'m awake!';

  return ( <>
    <Button className={classes.button} onClick={toggleSleep} color="#541388">
      {partyUser.data?.state === PartyUserState.AWAKE ? awakeText : asleepText}
    </Button>
  </> );
};
 
export default SleepToggle;