import * as React from 'react';
import { toast } from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import { useAuth } from '../../services/firebase/useAuth';
import { usePartyUser, usePartyUserMutation } from '../../services/firebase/usePartyUser';
import { PartyUserState } from '../../types/PartyUserState.enum';

interface NameProps {
  
}

const useStyles = createUseStyles({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 64,
    marginTop: 32,
  },
  nameInput: {
    marginRight: 25,
  },
  nameButton: {
    height: 50,
  },
});
 
const Name: React.FunctionComponent<NameProps> = () => {
  const classes = useStyles();
  const [name, setName] = React.useState<string>('');
  const { user: authUser } = useAuth();
  const NO_USER = 'NO_USER';
  const userId = authUser?.uid || NO_USER;

  const { data: partyUser } = usePartyUser();

  React.useEffect(() => {
    if (partyUser?.name) {
      setName(partyUser.name);
    }
  }, [partyUser?.name]);
  
  const mutation = usePartyUserMutation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    setName(rawValue);
  };

  const submitName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    let party = null;
    let state = PartyUserState.AWAKE;
    if (partyUser) {
      party = partyUser.party;
      state = partyUser.state;
    }

    toast.promise(mutation.mutateAsync({ 
      party,
      state,
      name: name.trim(),
      id: userId,
    }), {
      loading: 'Updating name',
      success: 'Updated name',
      error: 'Failed to update name',
    });
  };

  const isValidName = () => {
    if (name.length < 1 || name.length > 16) {
      return false;
    }

    return true;
  };

  return ( <>
    <form className={classes.nameContainer} onSubmit={e => e.preventDefault()}>
      <Input className={classes.nameInput} placeholder="Name" color="#D3D3D3" value={name} onChange={onChange}></Input>
      <Button className={classes.nameButton} color="#2FF22F" disabled={!isValidName()} onClick={submitName}>Submit</Button>
    </form>
  </> );
};
 
export default Name;