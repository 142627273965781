import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePartyUser } from '../../services/firebase/usePartyUser';
import Info from './Info';
import Leave from './Leave';
import SleepToggle from './SleepToggle';
import Users from './Users';

interface PartyPageProps {}

const PartyPage: React.FunctionComponent<PartyPageProps> = () => {
  const partyUser = usePartyUser();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!partyUser.isLoading && partyUser.data?.party == null) {
      navigate('/');
    }
  }, [partyUser]);

  return (
    <>
      <SleepToggle />
      <Users />
      <Info />
      <Leave />
    </>
  );
};

export default PartyPage;
