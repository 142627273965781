import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { neonifyContainer } from '../../utils/neon-utils';

interface InputProps {
  color?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  placeholder?: string;
}

const useStyles = createUseStyles({
  inputContainer: (color: string) => ({
    boxShadow: neonifyContainer(color),
    border: '3px solid white',
    borderRadius: 3,
    display: 'flex',
  }),
  input: {
    borderBlock: 'none',
    borderInline: 'none',
    color: '#444444',
    fontSize: 24,
    padding: 9,
    outline: 'none',
    width: '100%',
    border: 'none',
    height: '100%',
    borderRadius: 0,
  },
});

const Input: React.FunctionComponent<InputProps> = ({ color = 'red', className, onChange, value, placeholder }) => {
  const classes = useStyles(color);
  return (
    <>
      <div className={`${classes.inputContainer} ${className}`}>
        <input placeholder={placeholder} className={`${classes.input} monospace`} type="text" onChange={onChange} value={value} />
      </div>
    </>
  );
};

export default Input;
