import { useMutation } from '@tanstack/react-query';
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import * as React from 'react';
import { PartyUser } from '../../types/PartyUser.model';
import { db } from './firestore';
import { firestoreConverter } from './firestoreConverter';
import { createParty } from './functions';
import { useAuth } from './useAuth';
import { usePartyUser } from './usePartyUser';

export const useParty = () => {
  const auth = useAuth();

  const create = useMutation({
    mutationFn: async (_: any) => {
      await createParty();
    },
  });

  const join = useMutation({
    mutationFn: async (partyId: string) => {
      const userRef = doc(db, 'users', auth.userId);
      await updateDoc(userRef, { party: partyId });
    },
  });

  const leave = useMutation({
    mutationFn: async (_: any) => {
      const userRef = doc(db, 'users', auth.userId);
      await updateDoc(userRef, { party: null });
    },
  });

  return { create, join, leave };
};

export const usePartyList = () => {
  const partyUser = usePartyUser();
  const partyId = partyUser.data?.party;
  const [partyList, setPartyList] = React.useState<PartyUser[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (partyId == null) {
      return;
    }

    const usersRef = collection(db, 'users').withConverter(firestoreConverter<PartyUser>());
    const q = query(usersRef, where('party', '==', partyId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const userList = snapshot.docs.map(doc => {
        const { id, name, party, state } = doc.data();
        return { id, name, party, state };
      });

      setPartyList(userList);
      setIsLoading(false);
    });

    return unsubscribe;
  }, [partyId]);

  return { data: partyList, isLoading };
};
